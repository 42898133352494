<template>
  <div class="search-container">
    <div class="head">
      <div class="input-container">
        <div class="input">
          <input
            type="text"
            id="keywordinput"
            v-model="seach"
            @keyup.enter="searchInfo"
            placeholder="请输入搜索内容"
          />
          <button @click="searchInfo">
            <span></span>
          </button>
        </div>
        <!-- <div class="hint">请先输入关键词,再搜索</div> -->
      </div>
    </div>
    <div class="type-nav">
      <span :class="typeActive == 1?'active':''" @click="goToArticle">文章</span>
      <span :class="typeActive == 2?'active':''" @click="goToNewsFlash">快讯</span>
      <!-- <span  :class="typeActive == 3?'active':''" @click="goToRecommend">推荐</span> -->
      <!-- <span  :class="typeActive == 4?'active':''" @click="goToTheme">主题</span> -->
      <span :class="typeActive == 5?'active':''" @click="goToAuthor">作者</span>
      <hr />
    </div>
    <div class="main">
      <router-view />
      <!-- <V-ArticlePage ref="varticleaage" :searchWord="searchWord"></V-ArticlePage> -->
    </div>
    <div class="foot">
      <!-- <V-HotLabel :hotWords="hotWords"></V-HotLabel> -->
    </div>
  </div>
</template>

<script>
// import HotLabel from '@/components/hot-label/index.vue'
import ArticlePage from "@/views/academic-headlines/search/article/index.vue";
import { dataFormat } from "@/js/common/index.js";
import { DELETE, QUERY, UPDATE, INSERT } from "@/services/dao.js";
export default {
  data() {
    return {
      typeActive: 1,
      hidePageNav: true,
      searchWord: "",
      hotWords: [],
      pageNum: 0,
      pageSize: 1,
      totlePage: 0,
      orderActive: 1,
      newarticlelist: [],
      seach: " "
    };
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path != from.path) {
        if (to.path === "/search" || to.path === "/search/content") {
          this.typeActive = 1;
          return;
        }
        if (to.path === "/search/newsflash") {
          this.typeActive = 2;
          return;
        }
        if (to.path === "/search/recommend") {
          this.typeActive = 3;
          return;
        }
        if (to.path === "/search/theme") {
          this.typeActive = 4;
          return;
        }
        if (to.path === "/search/author") {
          this.typeActive = 5;
          return;
        }
      }
    }
  },
  created: async function() {
    ////搜索加载默认为文章
    this.seach = this.$route.params.name;
    this.typeActive = 1;
    this.searchInfo();

    // if(this.$route.path==='/search'||this.$route.path==='/search/content'){
    //     this.typeActive=1;
    // }
    // if(this.$route.path==='/search/newsflash'){
    //     this.typeActive=2;
    // }
    // if(this.$route.path==='/search/recommend'){
    //     this.typeActive=3;
    // }
    // if(this.$route.path==='/search/theme'){
    //     this.typeActive=4;
    // }
    // if(this.$route.path==='/search/author'){
    //     this.typeActive=5;
    // }
    //   let info= await QUERY("post","",'keyword(limit: 50) {        id        word  }');
    //   this.hotWords.splice(0,this.hotWords.length);
    //   for(let i=0;i<info.data.keyword.length;i++){
    //       this.hotWords.push(info.data.keyword[i]);
    //   }
  },
  methods: {
    //回车事件
    Searched() {
      this.typeActive = this.typeActive;
      if (this.typeActive == 1) {
        //加数据到关键字热门
        if (
          this.seach != "undefined" ||
          this.seach != null ||
          this.seach != ""
        ) {
          this.jia();
        }
      }
      this.searchInfo();
    },
    //关键进入文章页面
    goToArticle() {
      //加数据到关键字热门
      //加数据到关键字热门
      if (this.seach != "undefined" || this.seach != null || this.seach != "") {
        this.jia();
      }
      this.typeActive = 1;
      this.searchInfo();
    },
    //关键词进入快讯页面
    goToNewsFlash() {
      this.typeActive = 2;
      this.searchInfo();
    },
    //关键词进入推荐页面
    goToRecommend() {
      this.typeActive = 3;
      this.searchInfo();
    },
    //进入主题页面
    goToTheme() {
      this.typeActive = 4;
      this.searchInfo();
    },
    //进入作者页面
    goToAuthor() {
      this.typeActive = 5;
      this.searchInfo();
    },
    //点击查询
    async searchInfo() {
      this.searchWord = this.seach;
      if (this.typeActive == 1) {
        this.$router.push({
          path: "/contents",
          query: {
            searchWord: this.searchWord
          }
        });
      } else if (this.typeActive == 2) {
        this.$router.push({
          path: "/newsflashs",
          query: {
            searchWord: this.searchWord
          }
        });
      } else if (this.typeActive == 5) {
        this.$router.push({
          path: "/author",
          query: {
            searchWord: this.searchWord
          }
        });
      }
      // switch(this.typeActive){
      //     case 1:
      //         this.$router.push({path:'/search/content', query: {
      //             searchWord: this.searchWord
      //         }});
      //         break;
      //     case 2:
      //         this.$router.push({path:'/search/newsflash', query: {
      //             searchWord: this.searchWord
      //         }});
      //         break;
      //     // case 3:
      //     //     this.$router.push({path:'/search/recommend', query: {
      //     //         searchWord: this.searchWord
      //     //     }});
      //         break;
      //     // case 4:
      //     //     this.$router.push({path:'/search/theme', query: {
      //     //         searchWord: this.searchWord
      //     //     }});
      //         break;
      //     case 5:
      //         this.$router.push({path:'/search/author', query: {
      //             searchWord: this.searchWord
      //         }});
      //         break;
      // }
    },
    async jia() {
      if (this.seach.split(" ").join("").length != 0) {
        ///搜索关键词不为空白
        //热门搜索加数据
        let info = await INSERT(
          "post",
          "",
          '    insert_TtHotSearchKeyword(objects: {createTime: "' +
            new Date() +
            '", word: "' +
            this.seach +
            '"}) {  affected_rows  }'
        );
        console.log(
          "搜索词添加成功" + info.data.insert_TtHotSearchKeyword.affected_rows
        );
      }
    }
  },
  //组件
  components: {
    //'V-HotLabel':HotLabel,
    // 'V-ArticlePage':ArticlePage
  }
};
</script>

<style>
.search-container {
  text-align: center;
}
.search-container > .head {
  height: 164px;
  background: rgba(229, 229, 229, 1);
  width: 100%;
  text-align: center;
  position: relative;
  display: inline-block;
}
.search-container > .head > .input-container {
  left: 50%;
  top: 50%;
  margin-left: -322px;
  margin-top: -24px;
  height: 44px;
  width: 645px;
  position: absolute;
}
.search-container > .head > .input-container > .input {
  height: 48px;
}
.search-container > .head > .input-container > .input input {
  width: 527px;
  height: 40px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border-width: 0px;
  padding-left: 24px;
}
.search-container > .head > .input-container > .input button {
  width: 88px;
  height: 42px;
  cursor: pointer;
  background: #0084ff;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border-width: 0px;
  vertical-align: bottom;
}
.search-container > .head > .input-container > .input > button > span {
  height: 24px;
  width: 24px;
  display: inline-block;
  background-image: url(~@/assets/fdj1.png);
  background-repeat: no-repeat;
  background-size: 25px 25px;
}
.search-container > .head > .input-container > .hint {
  margin-top: 28px;
  text-align: left;
}
.search-container > .main {
  min-height: 432px;
  margin-top: 34px;
  text-align: center;
  display: inline-block;
  width: 100%;
  background-color: #f7f7f7;
  padding-top: 36px;
}
.search-container > .foot {
  text-align: center;
  width: 100%;
  display: inline-block;
}

.search-container > .foot > .hot-keyword {
  display: inline-block;
  height: 185px;
  width: 900px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(99, 99, 99, 1);
  line-height: 34px;
  text-align: left;
}
.search-container > .foot > .hot-keyword > .content span {
  margin-right: 15px;
  display: inline-block;
}

/* 类型导航栏------- */
.search-container .type-nav {
  font-size: 16px;
  font-weight: normal;
  color: #060505;
  text-align: center;
  height: 36px;
}
.search-container .type-nav span:not(:first-of-type) {
  margin-left: 45px;
}
.search-container .type-nav > span {
  display: inline-block;
  width: 50px;
  position: relative;
  padding-top: 8px;
  cursor: pointer;
}
.search-container .type-nav > .active:after {
  content: " ";
  width: 6px;
  height: 6px;
  background: #0084ff;
  border-radius: 3px;
  display: inline-block;
  position: absolute;
  bottom: -18px;
  left: 50%;
  margin-left: -3px;
}
.search-container .type-nav hr {
  width: 829px;
  height: 0.5 px;
  border: 1px solid rgba(191, 191, 191, 1);
  margin-top: 14px;
  background-color: rgba(191, 191, 191, 1);
}
input {
  outline: none;
}
button {
  outline: none;
}
</style>